<template>
    <div>
<!--        <h1>each</h1> -->
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
created() {
   setTimeout(() =>{
       this.$router.push(`/${window.sessionStorage.getItem('pathUrl')}`)
},100)},
    mounted() {},
    methods: {},
};
</script>

<style lang='scss'>

</style>
